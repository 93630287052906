<template>
  <v-card>
    <v-toolbar flat dark class="toolbar" :color="variables.colorPrimary">
      <h5>Atenção! Alteração de em andamento!</h5>
      <v-spacer></v-spacer>
      <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
    </v-toolbar>
    <span class="text-field">Para continuar digite:</span>
    <span class="font-weight-bold">estou-ciente-da-alteração</span>
    <v-text-field class="text-field" v-model="confirmation"></v-text-field>
    <v-card-actions >
      <v-spacer></v-spacer>
      <span></span>
      <v-btn
        class="br-btn"
        :color="variables.colorPrimary"
        :disabled="confirmation !== 'estou-ciente-da-alteração'"
        @click="confirmar"
      >Confirmar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import modalCadastro from '@/views/cadastros/entities/mixins/modalCadastro'
import variables from '@/assets/styles/helpers/_variables.scss'

export default {
  name: 'ModalIsInterior',
  mixins: [modalCadastro],
  data: () => ({ 
    confirmation: '',
  }),
  mounted () {},
  computed: {
    variables: () => variables,
  },
  methods: { 
    closeModal () { 
      this.$emit('closeModal', true)
      this.confirmation = ''
    },

    confirmar () {
      this.$emit('confirmarAlteracao', true)
      this.confirmation = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.toolbar {
  font-size: 20px;
  color: white;
  margin-bottom: 16px;
}

.text-field {
  padding-right: 16px;
  padding-left: 16px;
}
</style>